<template>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <h2>Media</h2>
      </b-col>
      <b-col cols="*" class="mr-3">
        <b-button variant="primary" :to="{ name: 'uploadMedia' }"
          >Upload
        </b-button>
      </b-col>
    </b-row>
    <hr class="mt-1 mb-5" />

    <div>
      <b-form-input
        v-model="query"
        id="media-search-input"
        type="text"
        autocomplete="off"
        placeholder="Search for media"
        debounce="500"
        class="mb-3"
      ></b-form-input>

      <b-table
        id="media-table"
        ref="table"
        :items="provider"
        :filter="query"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        @row-clicked="rowClicked"
        empty-text="No media found."
        show-empty
        style="cursor: pointer"
      >
        <template v-slot:cell(url)="row">
          {{ row.item.url }}
        </template>

        <template v-slot:cell(created_at)="row">
          {{ toLocalTime(row.item.created_at) }}
        </template>

        <template v-slot:cell(media_type)="row">
          {{ mediaTypeMap[row.item.media_type] }}
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(actions)="row">
          <MediaButton :media="row.item" @changed="$refs.table.refresh()" />
        </template>
      </b-table>

      <b-row align-h="between" class="mt-3">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </b-col>
        <b-col cols="*" class="mr-3"> {{ rows }} media </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import MediaButton from "./MediaButton.vue";

export default {
  components: {
    MediaButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      rows: 0,
      query: "",
      mediaTypeMap: {
        image: "Image",
        video: "Video",
      },
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "url", label: "URL" },
        { key: "media_type", label: "Type" },
        {
          key: "created_at",
          label: "Date Created",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    toLocalTime(timeString) {
      let date = new Date(Date.parse(timeString));
      return date.toLocaleDateString();
    },
    rowClicked(item) {
      this.$router.push({ name: "editMedia", params: { id: item.id } });
    },
    async provider(ctx) {
      try {
        let response = await this.$http.get(
          this.$store.state.apiURL + "/media",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
            params: {
              query: ctx.filter,
              sort: ctx.sortBy != "" ? ctx.sortBy : "none",
              direction: ctx.sortDesc ? "desc" : "asc",
              per: ctx.perPage,
              page: ctx.currentPage,
            },
          }
        );
        this.currentPage = response.data.page.position.current;
        this.rows = response.data.page.data.total;

        return response.data.data;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
  },
};
</script>

<style>
</style>
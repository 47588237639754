<template>
  <b-dropdown no-caret size="sm" variant="primary" :disabled="deleting">
    <template v-slot:button-content>
      <b-icon icon="three-dots-vertical" /> <b-spinner small v-if="deleting" />
    </template>
    <b-dropdown-item @click="deleteMedia" :disabled="deleting"
      ><b-icon icon="trash-fill" scale="0.75" />Delete</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  methods: {
    async deleteMedia() {
      this.deleting = true;
      try {
        await this.$http.delete(
          this.$store.state.apiURL + "/media/" + this.media.id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        this.$emit("changed");
      } catch (error) {
        this.handleError(error);
      }
      this.deleting = false;
    },
  },
};
</script>
